<template>
    <div class="home_box box_1" ref="home_box">
      <div class="header relative">
        <div class="text cursor" @click="goToPage('/bigscreen/menuPage')">巴林右旗防疫大数据中心</div> 

        <div class="weather absolute top_10 right_30"><AmapWeather /></div>
      </div>

      <div class="content_box full flex_row_around">
        <div class="h_80 absolute left_center top_-10 mon_text lh_40 color_fff fs_24">
          ᠪᠠᠭᠠᠷᠢᠨ ᠪᠠᠷᠠᠭᠤᠨ ᠬᠣᠱᠢᠭᠤᠨ᠎ᠤ ᠬᠢᠵᠢᠭ᠎ᠢ ᠰᠡᠷᠭᠡᠢᠯᠡᠬᠦ ᠶᠡᠬᠡ ᠳ᠋ᠠᠢᠲ᠋ᠠ᠎ᠶᠢᠨ ᠲᠥᠪ
        </div>

        <!-- box1 -->
        <div class="left_box w_25_p flex_column_around">
          <div class="chart_item w_full h_24_p cursor">
            <div class="title"><span @click="goToPage('/bigscreen/countyPage')">土地面积</span></div>
            <bing-tu class="chart" :value="data1" />
          </div>

          <div class="chart_item w_full h_24_p cursor">
            <div class="title"><span @click="goToPage('/bigscreen/countyFarmal')">种植面积</span></div>
            <kong-xin-bing-tu class="chart" :value="data2" />
          </div>

          <div class="chart_item w_full h_24_p cursor">
            <div class="title"><span @click="goToPage('/')">农业总产量</span></div>
            <xian-xing-tu class="chart" :value="data3" />
          </div>

          <div class="chart_item w_full h_24_p cursor">
            <div class="title"><span @click="goToPage('/')">病虫害</span></div>
            <zhu-zhuang-tu class="chart" :value="data4" />
          </div>
        </div>

        <!-- box2 -->
        <div class="center_box w_50_p">

          <div class="map_box w_full h_75_p">
            <!-- <ba-lin-you-qi-map class="map"  @changeVillage="changeVillage" /> -->
            <div class="absolute top_40 left_20">
              <span class="fs_16 color_4dbee6">全旗存栏数量：</span>
              <span class="fs_40 font_number num_style color_fff">
                <animate-number from="0" :to="728443" duration="3000" />
              </span>
              <span class="fs_16 color_4dbee6 ml_5">头</span>
            </div>

            <div class="absolute top_100 left_20">
              <span class="fs_16 color_4dbee6">全旗种植产量：</span>
              <span class="fs_40 font_number num_style color_fff">
                <animate-number from="0" :to="324355" duration="3000" />
              </span>
              <span class="fs_16 color_4dbee6 ml_5">吨</span>
            </div>

            <map-com class="map" />
          </div>

          <div class="chart_box w_full h_25_p flex_row_around">
            <div class="chart_1 w_48_p h_full p_5">
              <div class="title"><span @click="goToPage('/')">智能化统计</span></div>
              <ban-bing-tu class="chart" :value="data5" />
            </div>

            <div class="chart_2 w_48_p h_full p_5">
              <div class="title"><span @click="goToPage('/bigscreen/mashineState巴林右旗机械化统计')">机械化统计</span></div>
              <zhu-zhuang-tu class="chart" :value="data6" />
            </div>
          </div>
        </div>

        <!-- box3 -->
        <div class="right_box w_25_p flex_column_around">
          <div class="chart_item w_full h_24_p cursor">
            <div class="title"><span @click="goToPage('/bigscreen/fangYiJiLu')">防疫统计</span></div>
            <dv-scroll-ranking-board :config="config10" class="chart fs_26_i" style="padding: 0px 20px !important;" />
            <!-- <zhu-zhuang-tu class="chart" :value="data10" /> -->
          </div>

          <div class="chart_item w_full h_24_p cursor ">
            <div class="title"><span @click="goToPage('/bigscreen/yuZhongJiLu')">育种统计</span></div>
            <dv-scroll-board 
              class="chart color_82fefe fs_26_i" 
              style="padding: 0px 20px !important;" 
              :config="config9" 
            />
            <!-- <xian-xing-tu class="chart" :value="data9" /> -->
          </div>

          <div class="chart_item w_full h_24_p cursor">
            <div class="title"><span @click="goToPage('/bigscreen/countyPage2')">牲畜存栏</span></div>
            <dv-capsule-chart :config="config7" class="chart" style="padding: 10px 20px !important;" />
            <!-- <bing-tu class="chart" :value="data7" /> -->
          </div>

          <div class="chart_item w_full h_24_p cursor">
            <div class="title"><span @click="goToPage('/bigscreen/countyAnimal')">养殖户规模</span></div>
            <kong-xin-bing-tu class="chart" :value="data8" />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import BaLinYouQiMap from '@/components/map/baLinYouQiMap.vue'
  import ZhuZhuangTu from '@/components/charts/zhu-zhuang-tu.vue';
  import ZhuZhuangTu1 from '@/components/charts/zhu-zhuang-tu1.vue';
  import XianXingTu from '@/components/charts/xian-xing-tu.vue';
  import BingTu from '@/components/charts/bing-tu.vue';
  import KongXinBingTu from '@/components/charts/kong-xin-bing-tu.vue';
  import BanBingTu from '@/components/charts/ban-bing-tu.vue';
  import LeiDaTu from '@/components/charts/lei-da-tu.vue';
  import MapCom from '@/components/map/home-map.vue';
  import AmapWeather from '@/components/weather/AmapWeather.vue';

  export default {
    name: 'HomeMon',
    components: {
      BaLinYouQiMap,
      ZhuZhuangTu,
      ZhuZhuangTu1,
      XianXingTu,
      BingTu,
      KongXinBingTu,
      BanBingTu,
      LeiDaTu,
      MapCom,
      AmapWeather
    },
    data() {
      return {
        data1: {},
        data2: {},
        data3: {},
        data4: {},
        data5: {},
        data6: {},
        data7: {},
        data8: {},
        data9: {},
        data10: {},

        config7: {
          data: [
            { name: '防疫牛', value: 6316 },
            { name: '育肥牛', value: 4123 },
            { name: '成年牛', value: 9892 },
            { name: '犊牛', value: 7521 },
          ],
          colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9'],
          unit: '头',
          showValue: true
        },

        config9: {
          waitTime: 3000,
          header: ['时间', '地区', '数量（头）'],
          rowNum: 5,
          headerBGC: "",    // #226acc
          headerHeight: 30, 
          oddRowBGC: "", 
          evenRowBGC: "",
          data: [
            ['2023-04-12', '索博日嘎镇', '32536'],
            ['2023-04-12', '幸福之路苏木', '32624'],
            ['2023-04-12', '巴彦胡硕镇', '63843'],
            ['2023-04-12', '查干沐沦苏木', '21324'],
            ['2023-04-12', '巴彦塔拉苏木', '23424'],
            ['2023-04-12', '大坂镇', '54546'],
            ['2023-04-12', '查干诺尔镇', '32434'],
            ['2023-04-12', '宝日勿苏镇', '47345'],
            ['2023-04-12', '西拉沐沦苏木', '54423'],
          ]
        },

        config10: {
          data: [
            { name: '牛', value: 34727 },
            { name: '山羊', value: 54727 },
            { name: '绵羊', value: 96427 },
            { name: '马', value: 3244 },
            { name: '毛驴', value: 2372 },
            { name: '骆驼', value: 1214 }
          ],
          unit: '头',
          rowNum: 4,
          waitTime: 3000,
          carousel: 'single',
          valueFormatter ({ value }) {
            console.warn(arguments)
            const reverseNumber = (value + '').split('').reverse()
            let valueStr = ''

            while (reverseNumber.length) {
              const seg = reverseNumber.splice(0, 3).join('')
              valueStr += seg
              if (seg.length === 3) valueStr += ','
            }

            return valueStr.split('').reverse().join('')
          }
        }
      }
    },
    created() {
    },
    mounted () {
      this.initChart()
    },
    destroyed() {
    },
    methods: {
      // 跳转页面 
      goToPage(val) {
        this.$router.push({
          path: val
        })
      },

      // 初始化图表
      initChart() {
        this.data1 = {
          unit: '万亩',
          data: [
            {"name": "草地", "value": 460, "unit": ''},
            {"name": "林地", "value": 738, "unit": ''},
            {"name": "耕地", "value": 436, "unit": ''},
            {"name": "其他", "value": 352, "unit": ''}
          ]
        }

        this.data2 = {
          unit: '万亩',
          data: [
            {"name": "玉米", "value": 460, "unit": ''},
            {"name": "小麦", "value": 738, "unit": ''},
            {"name": "西瓜", "value": 436, "unit": ''},
            {"name": "甜瓜", "value": 352, "unit": ''}
          ]
        }

        this.data3 = {
          unit: '吨',
          x: ['2019', '2020', '2021', '2022'],
          y: [1845, 3439, 3835, 4343]
        }

        this.data4 = {
          unit: '万亩',
          x: ['小麦锈病', '稻瘟病', '稻纹枯病', '蝗虫', '麦芽'],
          y: [15, 56, 34, 26, 38]
        }

        this.data5 = {
          unit: '件',
          data: [
            {"name": "开关", "value": 460, "unit": ''},
            {"name": "监控", "value": 738, "unit": ''},
            {"name": "传送带", "value": 436, "unit": ''},
            {"name": "搅拌机", "value": 152, "unit": ''},
            {"name": "清粪机", "value": 232, "unit": ''},
            {"name": "饮水机", "value": 352, "unit": ''}
          ]
        }

        this.data6 = {
          unit: '辆',
          x: ['拖拉机', '收割机', '打草机', '播种机'],
          y: [1845, 3439, 1435, 4343]
        }

        this.data7 = {
          unit: '头',
          data: [
            {"name": "成年牛", "value": 460, "unit": ''},
            {"name": "犊牛", "value": 738, "unit": ''},
            {"name": "育肥牛", "value": 436, "unit": ''},
            {"name": "防疫牛", "value": 352, "unit": ''}
          ]
        }

        this.data8 = {
          unit: '户',
          data: [
            {"name": "<30头", "value": 60, "unit": ''},
            {"name": "30-50头", "value": 738, "unit": ''},
            {"name": "50-100头", "value": 436, "unit": ''},
            {"name": ">100头", "value": 352, "unit": ''}
          ]
        }

        this.data9 = {
          unit: '头',
          yRoate: true,
          x: ['1月', '2月', '3月', '4月'],
          y: [45, 3439, 1835, 343]
        }

        this.data10 = {
          unit: '头',
          yRoate: true,
          x: ['1月', '2月', '3月', '4月'],
          y: [33, 1245, 2334, 1945, 245]
        }
      }
    }
  }
</script>



<style>
  .chart > .header { height: 30px !important; line-height: 30px !important; font-size: 18px; }
  .chart > .rows > .row-item { font-size: 18px !important; }
</style>

<style>
  @media screen and (min-width: 1400px) and (max-width: 1599px) {
    .chart > .rows > .row-item { font-size: 16px !important; }
  }

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .chart > .rows > .row-item { font-size: 14px !important; }
  }
</style>

<style lang="less" scoped>
  .color_82fefe { color: #82fefe; }
  .fs_26_i { font-size: 26px !important; }
  .home_box {
    width: 100%;
    height: 100%;
    padding: 0 !important;
    // background-image: url('../assets/images/bgi/img6.webp');
    background-size: 100% 100%;

    .content_box {
      width: calc(100% - 60px);
      height: calc(100% - 80px);
      padding: 0;
      position: absolute;
      top: 53px;
      left: 30px;
      /* padding: 30px; */
      // background-image: url('../assets/images/bgi/img1.png');

      /* =================== box1 ==================== */
      .left_box {
        z-index: 99;
        background-image: none;
        .chart_item {
          //  background-image: url('../assets/images/ba-lin-you-qi/img4.png'); 
          background-size: 100% 100%;
          /* border: 1px solid #037df7; */

          .title { 
            width: 100%;
            height: 12%;
            position: relative;
           
            span {
              font-weight: bold; 
              cursor: pointer; 
              position: absolute;
              bottom: 0 !important;
              left: 20px;
              padding: 0px 5px;
              box-sizing: border-box;
              letter-spacing: 2px;
            }
          }

          .chart {
            width: 100%;
            height: 88%;
            position: relative;
            padding: 0 !important;
          }
        }
      }

      /* =================== box2 ==================== */
      .center_box {
        position: relative;
        .map_box {
          width: 200%; height: 100%;
          margin-left: -50%;
          z-index: -1;
          .map { width: 100%; height: 75%; }
        }

        .chart_box {
          position: absolute;
          bottom: 0;
          z-index: 99;
          .chart_1, .chart_2 {
            background-size: 100% 100%;
            .title { 
              width: 100%; 
              height: 10%; 
              position: relative;
              z-index: inherit;

              span {
                font-weight: bold; 
                cursor: pointer; 
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
              }
            }

            .chart {
              width: 100%;
              height: 90%;
            }
          } 
        }
      }
      .right_box {
        z-index: 99;
        .chart_item {
          /*  background-image: url('../assets/images/ba-lin-you-qi/img5.png'); */
          background-size: 100% 100%;

          .title { 
            width: 100%;
            height: 12%;
            position: relative;

            span {
              font-weight: bold; 
              cursor: pointer; 
              position: absolute;
              bottom: 0;
              right: 20px;
            }
          }

          .chart {
            width: 100%;
            height: 88%;
            position: relative;
            padding: 0 !important;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    .title { 
      font-size: 10px !important;
    }
  }
</style>
